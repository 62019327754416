.codeLanguageButton:hover{
    color:rgb(64,80,100);
}

.codeLanguageButton:active{
color: rgb(34,50,70);
}

.codeLanguageButton{
    color:rgb(94,110,130);
    border: 0px;
    background-color: transparent;
    transition: color .5s;
}

.BlueLink{
	color:var(--falcon-link-color);
	cursor:pointer;
}
.BlueLink:hover{
	text-decoration: underline;
}

.card:first-of-type{
    margin-top: 0px;
}

.card{
    margin-top: 20px;
}
:root{
--falcon-light-rgb: 246,246,250 !important;/*rgb(249,250,253)*/
}
